<template>
  <div class="container-fluid">
    <div class="col-sm-12 mx-auto py-3 d-flex flex-column">
      <div class="RateLogo d-flex my-5 justify-content-center">
        <img src="@/assets/icon.png" class="img-fluid w-50" alt="logo" />
      </div>

      <b-card class="RateForm my-2 w-100 col-12 col-sm-6 mx-auto text-center">
        <div class="my-5">
          <p class="mb-0">{{ $t("RateText1") }}</p>
          <star-rating
            style="display: flex; flex-direction: column"
            v-model="form.RateVal1"
            :read-only="false"
            :star-size="28"
            :show-rating="false"
            v-bind:rtl="true"
            active-color="#fe6a00"
            :glow="1"
            :animate="true"
          ></star-rating>
        </div>
        <div class="my-5">
          <p class="mb-0">{{ $t("RateText2") }}</p>
          <star-rating
            style="display: flex; flex-direction: column"
            v-model="form.RateVal2"
            :read-only="false"
            :star-size="28"
            :show-rating="false"
            v-bind:rtl="true"
            active-color="#fe6a00"
            :glow="1"
            :animate="true"
          ></star-rating>
        </div>
        <div class="my-2 mx-3">
          <b-form-input
            :placeholder="$t('RateName')"
            :state="validateState('RateName')"
            v-model="$v.form.RateName.$model"
          >
          </b-form-input>
          <b-form-invalid-feedback
            >{{ $t("RateDescValidate") }}
            {{ $v.form.RateName.$params.minLength.min }}
            {{ $t("RateDescValidate2") }}</b-form-invalid-feedback
          >
        </div>
        <div class="mb-4 mt-2 mx-3">
          <b-form-textarea
            id="textarea-small"
            rows="3"
            :placeholder="$t('RateText3')"
            :state="validateState('RateDesc')"
            v-model="$v.form.RateDesc.$model"
          ></b-form-textarea>
          <b-form-invalid-feedback
            >{{ $t("RateDescValidate") }}
            {{ $v.form.RateDesc.$params.minLength.min }}
            {{ $t("RateDescValidate2") }}</b-form-invalid-feedback
          >
        </div>
        <b-button
          variant="outline-primary"
          class="mx-auto my-4"
          @click="SaveRate()"
          >{{ $t("send") }}</b-button
        >
      </b-card>
    </div>
    <b-modal
      v-model="show"
      id="modal-center"
      centered
      title=""
      hide-footer
      size="md"
    >
      <template #modal-header>
        <h3 class="mx-auto text-primary">شكراً لكم!</h3>

      </template>
      <div class="mx-auto text-center mb-2 mt-2">
        <p class="mx-auto text-primary">شكراً لك على تقييمك عميلنا العزيز 😊، نقدر ذلك حقاً</p>
      
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

import StarRating from "vue-star-rating";
import { required, minLength } from "vuelidate/lib/validators";

export default {
     metaInfo: {
    title: "Rate Us",
  },
  components: {
    StarRating,
  },
  validations: {
    form: {
      RateVal1: {
        required,
      },
      RateVal2: {
        required,
      },
      RateName: {
        required,
        minLength: minLength(3),
      },
      RateDesc: {
        required,
        minLength: minLength(5),
      },
    },
  },
  data() {
    return {
      value1: "",
      show: false,
      form: {
        RateVal1: 4,
        RateVal2: 4,
        RateName: "",
        RateDesc: "",
      },
    };
  },
  mounted(){
 this.$gtag.event('Rate Us Page', { method: 'Google' })


  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    SaveRate: function () {
      let self = this;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var url = process.env.VUE_APP_DEVBASEURL + "/SaveRate";
        axios.post(url, this.form).then(function () {
          self.show = true;
          setTimeout(() => {
            self.show = false;
            self.$router.push({ name: "Home" });
          }, 3000);
        });
      }
    },
  },
};
</script>

<style scoped>
</style>